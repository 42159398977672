'use strict'

import BaseService from './baseService'
import { Constants } from '../utils/constants'

const serviceURL = `/api/${Constants.BASE_API_VERSION}/movements`

export default class MovementService extends BaseService {
  async getAllMovementsForLocations (locationId, month) {
    let url = `${serviceURL}/location/${locationId}`
    if (month) {
      url += `/?monthAndYear=${month}`
    }
    return this.sendRequest('GET', url)
  }

  async getAllMovementsForLocation (searchParams) {
    const from = searchParams.from || ''
    const to = searchParams.to || ''
    const locationId = searchParams.locationId || ''
    const vesselId = searchParams.vesselId || ''
    const bargeId = searchParams.bargeId || ''
    const mode = searchParams.mode === 0 ? 0 : searchParams.mode || ''
    const reason = searchParams.reason === 0 ? 0 : searchParams.reason || ''
    const status = searchParams.status === 0 ? 0 : searchParams.status || ''
    const userGroupId = searchParams.userGroupId || ''
    const chargeCode = searchParams.chargeCode || ''
    const url = `${serviceURL}/search?from=${from}&to=${to}&locationId=${locationId}&vesselId=${vesselId}&bargeId=${bargeId}&userGroupId=${userGroupId}&mode=${mode}&reason=${reason}&status=${status}&chargeCode=${chargeCode}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result
  }

  async getMovementPendingUser (searchParams) {
    const from = searchParams.from || ''
    const to = searchParams.to || ''
    const locationId = searchParams.locationId || ''
    const vesselId = searchParams.vesselId || ''
    const bargeId = searchParams.bargeId || ''
    const mode = searchParams.mode === 0 ? 0 : searchParams.mode || ''
    const reason = searchParams.reason === 0 ? 0 : searchParams.reason || ''
    const status = searchParams.status === 0 ? 0 : searchParams.status || ''
    const userGroupId = searchParams.userGroupId || ''
    const chargeCode = searchParams.chargeCode || ''
    const url = `${serviceURL}/pending?from=${from}&to=${to}&locationId=${locationId}&vesselId=${vesselId}&bargeId=${bargeId}&userGroupId=${userGroupId}&mode=${mode}&reason=${reason}&status=${status}&chargeCode=${chargeCode}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result
  }

  async getMovement (movementId) {
    return this.sendRequest('GET', `${serviceURL}/${movementId}`)
  }

  async getMovementsPendingUser () {
    return this.sendRequest('GET', `${serviceURL}/pendingMe`)
  }

  async getMovementReportForMonth (month) {
    return this.sendRequest('GET', `${serviceURL}/report/month/${month}`)
  }

  async searchPilotage (searchParms) {
    console.log(searchParms)
    const locationId = searchParms.locationId || ''
    const vesselId = searchParms.vesselId || ''
    const bargeId = searchParms.bargeId || ''
    const mode = searchParms.mode === 0 ? 0 : searchParms.mode || ''
    const reason = searchParms.reason === 0 ? 0 : searchParms.reason || ''
    const status = searchParms.status === 0 ? 0 : searchParms.status || ''
    const userGroupId = searchParms.userGroupId || ''
    const chargeCode = searchParms.chargeCode || ''
    const from = searchParms.from || ''
    const to = searchParms.to || ''
    const url = `${serviceURL}/searchPilotage?locationId=${locationId}&vesselId=${vesselId}&bargeId=${bargeId}&userGroupId=${userGroupId}&mode=${mode}&reason=${reason}&status=${status}&chargeCode=${chargeCode}&from=${from}&to=${to}`
    let result = await this.sendRequest('GET', encodeURI(url))
    return result
  }


  async createMovement(movement) {
    console.log('entered create ', movement)
    return this.sendRequest('POST', `${serviceURL}`, movement)
  }

  async updateFuelData (fuelDataRequest) {
    let request = { ...fuelDataRequest }
    delete request.id
    return this.sendRequest('PUT', `${serviceURL}/updateFuelData/${fuelDataRequest.id}`, request)
  }

  async updateCargoData (cargoDataRequest) {
    let request = { ...cargoDataRequest }
    delete request.id
    return this.sendRequest('PUT', `${serviceURL}/updateCargoData/${cargoDataRequest.id}`, request)
  }

  async submitMovement (submitDataRequest) {
    return this.sendRequest('PUT', `${serviceURL}/submitMovement/`, submitDataRequest)
  }

  async cancelMovementEntry (movementToCancelId) {
    return this.sendRequest('DELETE', `${serviceURL}/${movementToCancelId}`)
  }

  async downloadDocument (movementId, documentId) {
    let result = await this.sendRequest('GET', `${serviceURL}/downloadDocument/${movementId}/${documentId}`, null, null, 'blob')
    return result.data
  }
}
